@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

body {
  margin: 0;
  font-family: 'Poppins';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}

a {
    text-decoration: inherit;
    color: inherit;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.wrapper {
  /* width: 1920px; */
  margin: 0;
  padding: 2.5em 0 0;
  background-color: #ffffff;
  color: #00546e;
}

.container-padding {
  padding-left: 2%;
  padding-right: 2%;
}

.container-padding.home-top {
  background-color: #2fb9e4;
  padding-top: 3em;
  background-image: url(/static/media/home-image.06d62cb9.png);
  background-repeat: no-repeat;
  background-size: auto;
}

/* .home-product-selector {
  
} */

.container-padding.home-bottom-bottom {
  background-color: #00546e;
}

/* Header */
#header {
  position: relative;
  display: inline-block;
  margin: 0;
  padding: 0;
  border: none;
  width: 100%;
  text-align: center;
}

.header-top {
  display: block;
  margin-bottom: 2.5em;
}

.header-bottom {
  text-align: left;
  width: 100%;
  margin: auto;
  background: white;
  position: relative;
  display: block;
}

.header-active {
  background-color: #2fb9e4;
}

.header-active a {
  color: #ffffff!important;
}

.header-bottom ul {
  padding: 0;
  margin: 0;
  height: 3.7em;
  display: block;
}

.header-bottom ul li:first-child {
  display: inline-block;
  width: 16%;
  padding-left: 2%;
  height: 3.7em;
  line-height: 3.7em;
  text-align: center;
}

.header-bottom ul li:last-child {
  display: inline-block;
  width: 16%;
  padding-right: 2%;
  height: 3.7em;
  line-height: 3.7em;
  text-align: center;
}

.header-bottom li {
  display: inline-block;
  width: 16%;
  height: 3.7em;
  line-height: 3.7em;
  text-align: center;
}

.header-bottom a {
  font-size: 1.5em;
  color: #00546e;
}

.header-top .left {
  display: inline-block;
  width: 20%;
  vertical-align: top;
}

.header-top .middle {
  display: inline-block;
  width: 60%;
  vertical-align: top;
}

.header-top .right {
  display: inline-block;
  width: 20%;
  vertical-align: top;
  text-align: right;
}

/* Header top left (phone and select) */
.header-top .left .phone {
  font-size: 1em;
  display: inline-block;
} 

.select-country {
    display: inline-block; 
    margin-left: 1em;
}

.select-country select {

  border: none;
    color: #00546e;
    /* width: 100%; */
    font-size: 1.5em;
    font-family: 'Poppins';
    margin-top: .97em;
}

*:focus {
    outline: none;
}

.header-top .select-country select {
  width: 8.25em;
  height: 2.5em;
  font-size: 1em;
  color: #d2d2d2;
  border: none;
}

.header-top .select-country select option {
  background-color: #f9f9f9;
  font-size: 1em;
  text-align: left;
  color: #00546e;
} 

/* Header middle (logo and paragraph) */
.header-top .middle p {
  font-style: italic;
}

/* Header right */
.header-top .right p {
  display: inline-block;
}

.header-top .right a {
  display: inline-block;
  margin-left: 1em;
  color: #00546e;
}

/* Footer */
.footer {
  width: 100%;
  position: relative;
  padding-bottom: 3em;
  padding-top: 1em;
}

.footer-left {
  width: 66%;
  display: inline-block;
  vertical-align: top;
}

.footer-right {
  width: 34%;
  display: inline-block;
  text-align: right;
  box-sizing: border-box;
  padding-right: 8%;
}

.footer-right img {
  width: 9.6em;
  height: 2.5em;
}

.footer-right h2 {
  font-size: 1.6em;
}

.footer-right p, .footer-right a {
  font-size: 1em;
  color: #77939c;
}

.footer-left-item {
  display: inline-block;
  vertical-align: top;
  width: 23%;
  padding: 0 1%;
  word-wrap: break-word;
}

.footer-left-item h2 {
    font-size: 1.6em;
    font-weight: normal;
    /* text-decoration: underline; */
}

.footer-left-item p {
    font-size: 1em;
    color: #00546e;
}

.footer-left-item a {
    display: block;
    font-size: 1em;
    color: #00546e;
    font-weight: normal;
    text-decoration: underline;
}

/* Home page */
.home-top-content {
  /* background-color: #2fb9e4;
  width: 100%;
  padding: 50px 0; */
}

.home-top-content .title {
  margin-left: 8.33%;
  width: 33%;
  display: inline-block;
}

.home-top-content .title p {
  font-size: 1em;
  text-align: left;
  color: #ffffff;
  text-decoration: underline;
}

.home-top-content .title h1 {
  font-size: 4em;
  font-weight: bold;
  text-align: left;
  color: #ffffff;
  line-height: 1em;
}

.home-top-content .social-buttons {
  display: inline-block;
  vertical-align: top;
  position: absolute;
  right: 10.33%;
}

.home-top-content .social-buttons button {
  width: 8.2em;
  height: 2.5em;
  border-radius: 2.5em;
  border: solid 1px #ffffff;
  font-size: 1em;
  text-align: center;
  color: #ffffff;
  background-color: #2fb9e4;
  margin-left: 1em;
}

.home-top-bottom {
  /* width: 90%;
  margin-left: 5%; */
  position: relative;
  display: block;
  /* margin-top: 70px; */
}

.home-top-bottom p {
  font-size: 1em;
  text-align: left;
  color: #ffffff;
  margin-left: 8.33%;
  width: 33%;
}

.home-top-bottom .paragraph-inline, .home-top-bottom button {
  display: inline-block;
}

.home-top-bottom button {
  border-radius: 2.5em;
  border: solid 1px #ffffff;
  font-size: 1em;
  text-align: center;
  color: #ffffff;
  background-color: #2fb9e4;
  width: 16.66%;
  height: 2.5em;
}

.home-top-content .line {
  border-left: 1px solid #ffffff;
  height: 10em;
  display: inline-block;
}

.home-top-content .line-container {
  text-align: center;
  margin: 2em 0;
}

.home-middle-content {
  margin: 5% 0;
}

.home-middle-content div.left, .home-middle-content div.middle, .home-middle-content div.right {
  display: inline-block;
} 

.home-middle-content div.left {
  margin-left: 8.33%;
  margin-right: 2%;
  width: 24%;
  vertical-align: top;
} 

.home-middle-content div.middle {
  width: 16.66%;
  vertical-align: top;
}

.home-middle-content div.middle ul {
  list-style-type: none;
  -webkit-padding-start: 0;
          padding-inline-start: 0;
}

.home-middle-content div.middle ul li {
  height: 2.5em;
  border-bottom: 1px solid #dfe0e1;
  margin: 1.4em 0;
}

.home-middle-content div.middle ul li a {
  color: #00546e;
}

.home-middle-content div.middle p {
  color: #2295b7;
}


.home-middle-content div.right {
  width: 41.65%;
}

.home-middle-content div.right img {
  max-width: 80%;
  height: auto;
  margin-left: 10%;
}

.localtime {
    background: whitesmoke;

}

.localtime-item {
  display: inline-block;
  width: 8.33%;
  margin: 10% 8.33%;
  text-align: center;
}


.circle p {
  font-weight: bold;
  color: #00546e;
}

.localtime-item span {
  color: #77939c;
}

.my-localtime p {
  font-weight: bold;
  color: #2fb9e4;
}

.my-localtime {
  height: auto;
  width: 0;
  height: 0;
  padding: 50% 50%;
  border-radius: 50%;
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

.circle {
  height: auto;
  width: 0;
  height: 0;
  padding: 50% 50%;
  border: solid 2px #00546e;
  border-radius: 50%;
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}


.home-product-selector-left {
  display: inline-block;
  width: 50%;
  text-align: left;
  /* padding-right: 8%; */
  /* padding-left: 2%; */
  padding-left: 2%;
  box-sizing: border-box;
  background-image: url(/static/media/v-autonomna-stanica-prava.5ff96123.png);
  background-repeat: no-repeat;
  background-position: center;
  position: relative; 
}

.home-product-selector-left div {
  display: block;
  text-align: right;
  padding-right: 2em;
  height: 5em;
}

.home-product-selector-left img {
  position: absolute;
    height: 3em;
    top: 1.5em;
}

.home-product-selector-left h4 {
  font-size: 1.5em;
  margin-top: .5em;
  margin-bottom: 0;
  color: #ffffff;
  text-decoration: underline;
}

.home-product-selector-left span {
  color: #dfe0e1;
}

.home-product-selector-right {
  display: inline-block;
  width: 50%;
  text-align: right;
  /* padding-left: 8%; */
  /* padding-right: 2%; */
  padding-right: 2%;
  box-sizing: border-box;
  background-image: url(/static/media/v-autonomna-stanica-prava.5ff96123.png);
  background-repeat: no-repeat;
  background-position: center; 
  position: relative;
}

.home-product-selector-right div {
  display: block;
  text-align: left;
  padding-left: 2em;
  height: 5em;
}

.home-product-selector-right img {
  position: absolute;
  height: 3em;
  top: 1.5em;
  right: 4%;
}

.home-product-selector-right h4 {
  font-size: 1.5em;
  margin-top: .5em;
  margin-bottom: 0;
  color: #ffffff;
  text-decoration: underline;
}

.home-product-selector-right span {
  color: #dfe0e1;
}

.home-bottom-bottom div.list {
  width: 64%;
  margin-left: 18%;
}

.home-bottom-bottom div.list ul {
  padding: 5.5em 0;
  -webkit-padding-start: 0;
          padding-inline-start: 0;
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0;
  text-align: center;
  list-style-type: none;
}

.home-bottom-bottom div.list ul li {
  padding: 0.6em;
}

.home-bottom-bottom div.list ul li:hover {
  background-color: #2fb9e4;
}

.home-bottom-bottom div.list ul li a {
  font-size: 1.6em;
  font-weight: bold;
  color: #ffffff;
}


.news-top {
    padding-left: 8.3%;
    padding-right: 8.3%;
}
.news-container {
    
    list-style-type: none;
    position: relative;
    padding: 0;
}

.news-container li{
    padding-bottom: 1em;
    padding-top: 1em;
    border-bottom: 1px solid #eee;
}

.news-container li:last-child {
    border: none;
}

.news-container img{
    width: 50%;
    display: inline-block;
}
.news-container div{
    display: inline-block;
    width: 48%;
    vertical-align: top;
    padding-left: 1%;
}

.news-container h2{
    font-weight: bold;
    font-size: 3em;
    margin-bottom: 0;
    margin-top: .5em;
    line-height: 1em;
    text-transform: uppercase;
}

.news-container p{
    margin-top: 0;
}


.news-archive {
    padding: 1em 8.3%;
    color: white;
    background: #00546e;
}

.news-archive h2{
    font-size: 3em;
}

.news-archive-years {
    list-style-type: none;
    position: relative;
    padding: 0;
}

.news-archive-years li{
    display: inline;
    padding: .5em;
    margin-right: 1em;
    border-radius: 1em;
    border: 1px solid white;
    cursor: pointer;
}

.news-archive-years li:hover{
    display: inline;
    padding: .5em;
    margin-right: 1em;
    border-radius: 1em;
    border: 1px solid white;
    color: #00546e;
    background: #fff;
}

.news-archive-months {
    list-style-type: none;
    position: relative;
    padding: 0;
    
}

.news-archive-months li {
    text-align: center;
    padding: 1em;
    border-bottom: 1px solid white;
    cursor: pointer;
}
.news-archive-months li:last-child {
    border: none;
}

.news-archive-months li:hover {
    background: #1a8fb3;
}

.single-news h5 {
    font-weight: normal;
}

.single-news-title {
    font-size: 3em;
    text-transform: uppercase;
    line-height: 1em;
}

.single-news-padding {
    padding-left: 8.3%;
    padding-right: 8.3%;
}

.single-news-featured-image {
    width: 100%;
}

.single-news-content {
    position: relative;
}
.single-news-content p, .single-news-content img, .single-news-content h3{
    display: inline-block;
    width: 70%;
    margin-left: 30%;
}
.single-news-content h2 {
    display: inline-block;
    width: 70%;
    font-size: 2em;
}

.page-title {
    font-size: 3em;
    line-height: 1em;
}

.about-us {
    padding-left: 8.3%;
    padding-right: 8.3%;
}

.about-us-top-content {
    position: relative;
    padding-bottom: 3em;
}

.about-us-top-content-left {
    display: inline-block;
    width: 20%;
    vertical-align: top;
    margin-right: 10%;
}

.about-us-top-content-mid {
    display: inline-block;
    width: 30%;
    margin-right: 10%;
}

.about-us-top-content-right {
    display: inline-block;
    width: 30%;
}

.about-us-bottom-content {
    padding-bottom: 3em;
}

.about-us-bottom-content-left {
    width: 45%;
    display: inline-block;
    margin-right: 5%;
    vertical-align: top;
}

.about-us-bottom-content-right {
    width: 50%;
    display: inline-block;
}

.about-us-bottom-content-right-left {
    width: 40%;
    display: inline-block;
    margin-right: 10%;
    vertical-align: top;
}

.about-us-bottom-content-right-right {
    width: 50%;
    display: inline-block;
}

.about-us-number {
    position: absolute;
    color: rgb(82, 143, 192);
    opacity: .5;
    left: 50%;
    top: -5rem;
    font-size: 15em;
    padding-top: 0;
    font-weight: bold;
}



/* About page */

